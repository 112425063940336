
@media (min-width:768px){
.sub-view.enter-secret-word{max-width:50%
}
}
@media (max-width:424px){
.sub-view.enter-secret-word .my-text-input{margin-top:14px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.enter-secret-word .my-text-input{margin-top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.enter-secret-word .my-text-input{margin-top:18px
}
}
@media (min-width:1024px){
.sub-view.enter-secret-word .my-text-input{margin-top:20px
}
}
@media (max-width:424px){
.sub-view.enter-secret-word .info-after-submit{margin-top:28px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.enter-secret-word .info-after-submit{margin-top:30px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.enter-secret-word .info-after-submit{margin-top:36px
}
}
@media (min-width:1024px){
.sub-view.enter-secret-word .info-after-submit{margin-top:42px
}
}
.sub-view.enter-secret-word .info-after-submit h3{margin-top:0
}