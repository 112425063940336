
@media (max-width:424px){
.more-info_unsubscribe-all .teardrop{margin-top:6px
}
}
@media (min-width:425px) and (max-width:767px){
.more-info_unsubscribe-all .teardrop{margin-top:7px
}
}
@media (min-width:768px) and (max-width:1023px){
.more-info_unsubscribe-all .teardrop{margin-top:7px
}
}
@media (min-width:1024px){
.more-info_unsubscribe-all .teardrop{margin-top:8px
}
}