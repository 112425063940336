
.list-of-valid-words.view ul.words{font-family:Hack,monospace
}
@media (max-width:424px){
.list-of-valid-words.view ul.words{margin-top:18px
}
}
@media (min-width:425px) and (max-width:767px){
.list-of-valid-words.view ul.words{margin-top:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.list-of-valid-words.view ul.words{margin-top:28px
}
}
@media (min-width:1024px){
.list-of-valid-words.view ul.words{margin-top:32px
}
}