
.my-text-submit-button{background-color:#4caf50;border-bottom:1px solid #003e03;border-right:1px solid #003e03;border-top:1px solid #003e03;border-top-right-radius:4px;border-bottom-right-radius:4px;-webkit-box-shadow:0 0 0 transparent;box-shadow:0 0 0 transparent;display:inline-block;-webkit-transition:-webkit-box-shadow .15s cubic-bezier(.47,0,.745,.715);transition:-webkit-box-shadow .15s cubic-bezier(.47,0,.745,.715);transition:box-shadow .15s cubic-bezier(.47,0,.745,.715);transition:box-shadow .15s cubic-bezier(.47,0,.745,.715),-webkit-box-shadow .15s cubic-bezier(.47,0,.745,.715);vertical-align:middle
}
@media (max-width:424px){
.my-text-submit-button{padding-left:5px;padding-right:5px
}
}
@media (min-width:425px) and (max-width:767px){
.my-text-submit-button{padding-left:7px;padding-right:7px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-text-submit-button{padding-left:8px;padding-right:8px
}
}
@media (min-width:1024px){
.my-text-submit-button{padding-left:8px;padding-right:8px
}
}
@media (max-width:424px){
.my-text-submit-button{height:34px
}
}
@media (min-width:425px) and (max-width:767px){
.my-text-submit-button{height:36px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-text-submit-button{height:36px
}
}
@media (min-width:1024px){
.my-text-submit-button{height:36px
}
}
.my-text-submit-button:focus{-webkit-box-shadow:0 0 10px #003e03;box-shadow:0 0 10px #003e03
}
.my-text-submit-button>.small-quill{height:26px;text-align:center;vertical-align:middle;width:26px
}
.my-text-submit-button>.small-quill>.outer-stem{stroke:#fff
}
.my-text-submit-button>.small-quill>.feather{fill:#fff
}
.my-text-submit-button>.small-quill>.inner-stem{fill:#4caf50
}