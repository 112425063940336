
@media (max-width:424px){
.init-player-info_secret-word h3{margin-bottom:14px
}
}
@media (min-width:425px) and (max-width:767px){
.init-player-info_secret-word h3{margin-bottom:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.init-player-info_secret-word h3{margin-bottom:18px
}
}
@media (min-width:1024px){
.init-player-info_secret-word h3{margin-bottom:20px
}
}
.init-player-info_secret-word h5:first-child{margin-top:0
}
@media (max-width:424px){
.init-player-info_secret-word p{margin-top:6px
}
}
@media (min-width:425px) and (max-width:767px){
.init-player-info_secret-word p{margin-top:7px
}
}
@media (min-width:768px) and (max-width:1023px){
.init-player-info_secret-word p{margin-top:7px
}
}
@media (min-width:1024px){
.init-player-info_secret-word p{margin-top:8px
}
}