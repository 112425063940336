
.unexpected-error>.teardrop{display:block
}
@media (max-width:424px){
.unexpected-error>.teardrop{margin-top:12px
}
}
@media (min-width:425px) and (max-width:767px){
.unexpected-error>.teardrop{margin-top:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.unexpected-error>.teardrop{margin-top:13px
}
}
@media (min-width:1024px){
.unexpected-error>.teardrop{margin-top:14px
}
}
@media (max-width:424px){
.unexpected-error>.teardrop{margin-bottom:14px
}
}
@media (min-width:425px) and (max-width:767px){
.unexpected-error>.teardrop{margin-bottom:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.unexpected-error>.teardrop{margin-bottom:18px
}
}
@media (min-width:1024px){
.unexpected-error>.teardrop{margin-bottom:20px
}
}