
.link-to{display:inline-block;line-height:1.4;position:relative
}
.link-to::before{background-color:transparent;bottom:0;-webkit-box-shadow:0 0 0 transparent;box-shadow:0 0 0 transparent;content:'';display:inline-block;left:0;right:0;position:absolute;top:0;-webkit-transition-duration:.15s;transition-duration:.15s;-webkit-transition-property:background-color,-webkit-box-shadow,-webkit-transform;transition-property:background-color,-webkit-box-shadow,-webkit-transform;transition-property:background-color,box-shadow,transform;transition-property:background-color,box-shadow,transform,-webkit-box-shadow,-webkit-transform;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715)
}
.link-to>span{position:relative
}
.link-to:focus{outline:0
}
.link-to:focus::before{bottom:0;content:'';display:inline-block;position:absolute;left:0;right:0;top:0;-webkit-transform:scale(1.2);transform:scale(1.2)
}
.link-to:not(.custom-focus):focus::before{-webkit-box-shadow:0 0 5px #3d496f;box-shadow:0 0 5px #3d496f
}