
.enter-guess{height:56px;line-height:56px
}
.enter-guess>.guess-form>.my-text-input>label{display:inline-block
}
@media (max-width:424px){
.enter-guess>.guess-form>.my-text-input>label{margin-right:12px
}
}
@media (min-width:425px) and (max-width:767px){
.enter-guess>.guess-form>.my-text-input>label{margin-right:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.enter-guess>.guess-form>.my-text-input>label{margin-right:13px
}
}
@media (min-width:1024px){
.enter-guess>.guess-form>.my-text-input>label{margin-right:14px
}
}
.enter-guess>.guess-form>.my-text-input>label::after{content:':'
}
.enter-guess>.guess-form>.my-text-input>.input-wrapper{border-top-right-radius:0;border-bottom-right-radius:0;display:inline-block;vertical-align:middle
}
@media (max-width:424px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{width:76px
}
}
@media (min-width:425px) and (max-width:767px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{width:102px
}
}
@media (min-width:768px) and (max-width:1023px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{width:102px
}
}
@media (min-width:1024px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{width:102px
}
}
@media (max-width:424px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{height:34px
}
}
@media (min-width:425px) and (max-width:767px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{height:36px
}
}
@media (min-width:768px) and (max-width:1023px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{height:36px
}
}
@media (min-width:1024px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper{height:36px
}
}
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{background-color:#fff;border-top-right-radius:0;border-bottom-right-radius:0;font-family:Hack,monospace;vertical-align:top
}
@media (max-width:424px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{width:74px
}
}
@media (min-width:425px) and (max-width:767px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{width:100px
}
}
@media (min-width:768px) and (max-width:1023px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{width:100px
}
}
@media (min-width:1024px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{width:100px
}
}
@media (max-width:424px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{height:32px;line-height:32px
}
}
@media (min-width:425px) and (max-width:767px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{height:34px;line-height:34px
}
}
@media (min-width:768px) and (max-width:1023px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{height:34px;line-height:34px
}
}
@media (min-width:1024px){
.enter-guess>.guess-form>.my-text-input>.input-wrapper>input#guess{height:34px;line-height:34px
}
}