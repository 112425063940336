
@media (max-width:424px){
.sub-view.init-player>.my-form{margin-top:18px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.init-player>.my-form{margin-top:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.init-player>.my-form{margin-top:28px
}
}
@media (min-width:1024px){
.sub-view.init-player>.my-form{margin-top:32px
}
}
.sub-view.init-player>.my-form label{width:150px
}
@media (max-width:424px){
.sub-view.init-player .info-after-submit{margin-top:28px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.init-player .info-after-submit{margin-top:30px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.init-player .info-after-submit{margin-top:36px
}
}
@media (min-width:1024px){
.sub-view.init-player .info-after-submit{margin-top:42px
}
}
.sub-view.init-player .info-after-submit>h3{margin-top:0
}
@media (max-width:424px){
.sub-view.init-player .info-after-submit>h3{margin-bottom:12px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.init-player .info-after-submit>h3{margin-bottom:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.init-player .info-after-submit>h3{margin-bottom:13px
}
}
@media (min-width:1024px){
.sub-view.init-player .info-after-submit>h3{margin-bottom:14px
}
}
@media (max-width:424px){
.sub-view.init-player .info-after-submit .just-kidding{margin-top:12px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.init-player .info-after-submit .just-kidding{margin-top:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.init-player .info-after-submit .just-kidding{margin-top:13px
}
}
@media (min-width:1024px){
.sub-view.init-player .info-after-submit .just-kidding{margin-top:14px
}
}