
.my-checkbox{-webkit-tap-highlight-color:transparent;display:inline-block
}
.my-checkbox>input{position:absolute;opacity:0
}
.my-checkbox>label{display:inline-block;-webkit-transition:color .3s cubic-bezier(.47,0,.745,.715);transition:color .3s cubic-bezier(.47,0,.745,.715);vertical-align:middle
}
@media (max-width:767px){
.my-checkbox>label{margin-bottom:0
}
}
.my-checkbox.is-disabled{cursor:default
}
.my-checkbox.is-disabled>label{color:#606060;cursor:default
}
.my-checkbox.is-disabled>.nice-looking-checkbox{background-color:#888;cursor:default
}
.my-checkbox.is-disabled>.nice-looking-checkbox>.check{color:#e6e6e6
}
.my-checkbox>.nice-looking-checkbox{background-color:#fff;border-radius:4px;border:1px solid #939393;display:inline-block;height:30px;text-align:center;-webkit-transition:background-color .3s cubic-bezier(.47,0,.745,.715);transition:background-color .3s cubic-bezier(.47,0,.745,.715);vertical-align:middle;width:30px
}
@media (max-width:424px){
.my-checkbox>.nice-looking-checkbox{margin-left:12px
}
}
@media (min-width:425px) and (max-width:767px){
.my-checkbox>.nice-looking-checkbox{margin-left:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-checkbox>.nice-looking-checkbox{margin-left:13px
}
}
@media (min-width:1024px){
.my-checkbox>.nice-looking-checkbox{margin-left:14px
}
}
.my-checkbox>.nice-looking-checkbox>.check{color:#131313;margin-top:2px;-webkit-transition:color .3s cubic-bezier(.47,0,.745,.715);transition:color .3s cubic-bezier(.47,0,.745,.715)
}