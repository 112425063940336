
.my-button{-webkit-box-shadow:0 3px 6px #aaa;box-shadow:0 3px 6px #aaa;border-radius:4px;display:inline-block;-webkit-transition:-webkit-box-shadow .3s cubic-bezier(.47,0,.745,.715);transition:-webkit-box-shadow .3s cubic-bezier(.47,0,.745,.715);transition:box-shadow .3s cubic-bezier(.47,0,.745,.715);transition:box-shadow .3s cubic-bezier(.47,0,.745,.715),-webkit-box-shadow .3s cubic-bezier(.47,0,.745,.715)
}
@media (max-width:424px){
.my-button{margin-top:28px
}
}
@media (min-width:425px) and (max-width:767px){
.my-button{margin-top:30px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-button{margin-top:36px
}
}
@media (min-width:1024px){
.my-button{margin-top:42px
}
}
.my-button:focus,.my-button[focus-within]{-webkit-box-shadow:0 5px 6px #777;box-shadow:0 5px 6px #777
}
.my-button:focus,.my-button:focus-within{-webkit-box-shadow:0 5px 6px #777;box-shadow:0 5px 6px #777
}
.my-button.disabled{-webkit-box-shadow:0 0 0 transparent;box-shadow:0 0 0 transparent
}
.my-button>button{border-radius:4px;font-weight:500;-webkit-transition-duration:.15s;transition-duration:.15s;-webkit-transition-property:background-color,border-color,-webkit-box-shadow,-webkit-transform;transition-property:background-color,border-color,-webkit-box-shadow,-webkit-transform;transition-property:background-color,border-color,box-shadow,transform;transition-property:background-color,border-color,box-shadow,transform,-webkit-box-shadow,-webkit-transform;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715);z-index:1
}
@media (max-width:1023px){
.my-button>button{padding:18px 28px
}
}
@media (min-width:1024px){
.my-button>button{padding:20px 32px
}
}
.my-button>button.primary{background-color:#4caf50;border:1px solid #4caf50;color:#fff
}
.my-button>button.primary:focus{border-color:#003e03
}
.my-button>button.secondary{background-color:#fff;border:1px solid #4c7eaf
}
.my-button>button.secondary:focus{border-color:#3d496f
}
.my-button>button:disabled{background-color:#888;border-color:#555;cursor:default
}
.my-button>button:not(:disabled).active,.my-button>button:not(:disabled):active{-webkit-transform:translateY(3px);transform:translateY(3px)
}