
@media (max-width:424px){
.view.email-unsubscriptions>form{margin-top:28px
}
}
@media (min-width:425px) and (max-width:767px){
.view.email-unsubscriptions>form{margin-top:30px
}
}
@media (min-width:768px) and (max-width:1023px){
.view.email-unsubscriptions>form{margin-top:36px
}
}
@media (min-width:1024px){
.view.email-unsubscriptions>form{margin-top:42px
}
}
@media (max-width:424px){
.view.email-unsubscriptions>form>.unsubscriptions>li+li{margin-top:18px
}
}
@media (min-width:425px) and (max-width:767px){
.view.email-unsubscriptions>form>.unsubscriptions>li+li{margin-top:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.view.email-unsubscriptions>form>.unsubscriptions>li+li{margin-top:28px
}
}
@media (min-width:1024px){
.view.email-unsubscriptions>form>.unsubscriptions>li+li{margin-top:32px
}
}
@media (max-width:424px){
.view.email-unsubscriptions>form .my-checkbox{margin-left:12px;margin-right:12px
}
}
@media (min-width:425px) and (max-width:767px){
.view.email-unsubscriptions>form .my-checkbox{margin-left:13px;margin-right:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.view.email-unsubscriptions>form .my-checkbox{margin-left:13px;margin-right:13px
}
}
@media (min-width:1024px){
.view.email-unsubscriptions>form .my-checkbox{margin-left:14px;margin-right:14px
}
}
@media (max-width:424px){
.view.email-unsubscriptions>form .my-checkbox{font-size:16px
}
}
@media (min-width:425px) and (max-width:767px){
.view.email-unsubscriptions>form .my-checkbox{font-size:17px
}
}
@media (min-width:768px) and (max-width:1023px){
.view.email-unsubscriptions>form .my-checkbox{font-size:17px
}
}
@media (min-width:1024px){
.view.email-unsubscriptions>form .my-checkbox{font-size:18px
}
}
.view.email-unsubscriptions .unsubscriptions>li button.alert{margin-top:-2px;vertical-align:middle
}
.view.email-unsubscriptions .unsubscriptions>li button.help{vertical-align:middle
}
.view.email-unsubscriptions .unsubscriptions>li button.help .help-circle{color:#fff;fill:#4c7eaf
}