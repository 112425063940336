
@media (max-width:424px){
#app.first-time-mobile>header{padding-bottom:28px
}
}
@media (min-width:425px) and (max-width:767px){
#app.first-time-mobile>header{padding-bottom:30px
}
}
@media (min-width:768px) and (max-width:1023px){
#app.first-time-mobile>header{padding-bottom:36px
}
}
@media (min-width:1024px){
#app.first-time-mobile>header{padding-bottom:42px
}
}
#app.first-time-mobile .view.room>.wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1
}
#app.first-time-mobile>footer{margin-top:0
}
.sub-view.first-time-mobile{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-bottom:80px
}
.sub-view.first-time-mobile>.board{display:block
}
.sub-view.first-time-mobile>.board .header .display-names>h4,.sub-view.first-time-mobile>.board .header .status{text-align:center
}
.sub-view.first-time-mobile>.board .header>.wrapper{position:relative
}
.sub-view.first-time-mobile>.board .header>.wrapper button.arrow-circle{bottom:0;position:absolute;z-index:1
}
.sub-view.first-time-mobile>.board .header>.wrapper button.arrow-circle.left{left:0
}
.sub-view.first-time-mobile>.board .header>.wrapper button.arrow-circle.left.disabled circle{fill:#777!important
}
.sub-view.first-time-mobile>.board .header>.wrapper button.arrow-circle.right{right:0
}
.sub-view.first-time-mobile>.board .header .display-names{overflow:hidden;white-space:nowrap;z-index:0
}
.sub-view.first-time-mobile>.board .header .display-names>h4{display:inline-block;font-size:22px;margin-top:0;width:100%
}
.sub-view.first-time-mobile>.steps{display:block
}
@media (max-width:424px){
.sub-view.first-time-mobile>.steps:not(.step-0){margin-top:14px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.first-time-mobile>.steps:not(.step-0){margin-top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.first-time-mobile>.steps:not(.step-0){margin-top:18px
}
}
@media (min-width:1024px){
.sub-view.first-time-mobile>.steps:not(.step-0){margin-top:20px
}
}
.sub-view.first-time-mobile>.steps>li:not(:first-child):not(.animate_shown){display:none
}