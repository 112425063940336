
@media (min-width:768px){
.home.view{max-width:50%
}
}
@media (max-width:424px){
.home.view>p+p{margin-top:18px
}
}
@media (min-width:425px) and (max-width:767px){
.home.view>p+p{margin-top:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.home.view>p+p{margin-top:28px
}
}
@media (min-width:1024px){
.home.view>p+p{margin-top:32px
}
}