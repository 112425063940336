/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
html{line-height:1.15;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%
}
body{margin:0
}
article,aside,footer,header,nav,section{display:block
}
h1{font-size:2em;margin:.67em 0
}
figcaption,figure{display:block
}
figure{margin:1em 40px
}
hr{-webkit-box-sizing:content-box;box-sizing:content-box;height:0;overflow:visible
}
main{display:block
}
pre{font-family:monospace,monospace;font-size:1em
}
a{background-color:transparent;-webkit-text-decoration-skip:objects
}
abbr[title]{border-bottom:none;text-decoration:underline;-webkit-text-decoration:underline dotted;text-decoration:underline dotted
}
b,strong{font-weight:inherit
}
b,strong{font-weight:bolder
}
code,kbd,samp{font-family:monospace,monospace;font-size:1em
}
dfn{font-style:italic
}
mark{background-color:#ff0;color:#000
}
small{font-size:80%
}
sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline
}
sub{bottom:-.25em
}
sup{top:-.5em
}
audio,video{display:inline-block
}
audio:not([controls]){display:none;height:0
}
img{border-style:none
}
svg:not(:root){overflow:hidden
}
button,input,optgroup,select,textarea{font-family:sans-serif;font-size:100%;line-height:1.15;margin:0
}
button{overflow:visible
}
button,select{text-transform:none
}
[type=reset],[type=submit],button,html [type=button]{-webkit-appearance:button
}
[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0
}
[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText
}
input{overflow:visible
}
[type=checkbox],[type=radio]{-webkit-box-sizing:border-box;box-sizing:border-box;padding:0
}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto
}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px
}
[type=search]::-webkit-search-cancel-button,[type=search]::-webkit-search-decoration{-webkit-appearance:none
}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit
}
fieldset{padding:.35em .75em .625em
}
legend{-webkit-box-sizing:border-box;box-sizing:border-box;display:table;max-width:100%;padding:0;color:inherit;white-space:normal
}
progress{display:inline-block;vertical-align:baseline
}
textarea{overflow:auto
}
details{display:block
}
summary{display:list-item
}
menu{display:block
}
canvas{display:inline-block
}
template{display:none
}
[hidden]{display:none
}
h1,h2,h3,h4,h5,h6{margin-top:0
}
button{background-color:transparent;border:0;outline:0!important;padding:0
}
button,input,optgroup,select,textarea{font-family:Raleway,sans-serif
}
html{-webkit-box-sizing:border-box;box-sizing:border-box
}
*,:after,:before{-webkit-box-sizing:inherit;box-sizing:inherit
}
button,input,optgroup,select,textarea{line-height:1.4
}
@font-face{font-family:Raleway;font-weight:400;font-style:normal;src:url(/580d0778ad254335be45bf58bb449f43.ttf) format("ttf") url(/18f82fd82e029a36656cdc7062f88a48.woff) format("woff")
}
@font-face{font-family:Raleway;font-weight:400;font-style:italic;src:url(/dd03a26a6d06f63d75ceeac6b491f26a.ttf) format("ttf") url(/a964c4662d0a28b086f32af67f5613c2.woff) format("woff")
}
@font-face{font-family:Raleway;font-weight:500;font-style:normal;src:url(/430a0518f5ff3b6c8968b759a29b36e2.ttf) format("ttf") url(/41e01043472584fbd95716ea872be328.woff) format("woff")
}
@font-face{font-family:Raleway;font-weight:500;font-style:italic;src:url(/ff3c8ca6aa39754108f381ba4d7d5c13.ttf) format("ttf") url(/da80a05d8bb17d1a86eea18e2a237308.woff) format("woff")
}
@font-face{font-family:Raleway;font-weight:600;src:url(/17ba6410cbc694808961a988fd4426de.ttf) format("ttf") url(/e49f3d18e8c27dc31a7e06f3534832b1.woff) format("woff")
}
@font-face{font-family:Hack;font-weight:400;src:url(/998e12da3b65d633fd16cf68c723e17c.ttf) format("ttf") url(/842db437ef371bc39e196e95d8c816f1.woff) format("woff")
}
@font-face{font-family:'Source Serif Pro';font-weight:600;src:url(/f8ac33e062d0688858d1dfa44960b2a7.ttf) format("ttf") url(/010d6008437bbdcad5ac686dc81f9b74.woff) format("woff")
}
@font-face{font-family:'Source Serif Pro';font-weight:400;src:url(/bcae00edeb14f3bdec0fdfcf97cd6b8c.ttf) format("ttf") url(/e82d90f1204793d671284383412da71d.woff) format("woff")
}
@media (max-width:424px){
form>ul>li+li{margin-top:28px
}
}
@media (min-width:425px) and (max-width:767px){
form>ul>li+li{margin-top:30px
}
}
@media (min-width:768px) and (max-width:1023px){
form>ul>li+li{margin-top:36px
}
}
@media (min-width:1024px){
form>ul>li+li{margin-top:42px
}
}
form label{display:inline-block
}
@media (max-width:767px) and (max-width:424px){
form button.help+label+.input-wrapper{margin-top:6px
}
}
@media (max-width:767px) and (min-width:425px) and (max-width:767px){
form button.help+label+.input-wrapper{margin-top:7px
}
}
@media (max-width:767px) and (max-width:424px){
form label:first-child+.input-wrapper{margin-top:3px
}
}
@media (max-width:767px) and (min-width:425px) and (max-width:767px){
form label:first-child+.input-wrapper{margin-top:3px
}
}
body,html{background-color:#fff;color:#131313;font-family:Raleway,sans-serif;font-size:16px;line-height:1.4
}
#app{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;min-height:100vh
}
#app>main{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1
}
#app>main>.site-container{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1
}
#app>main>.site-container>.view{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1
}
.site-container{margin:0 auto;max-width:1024px;width:100%
}
@media (max-width:424px){
.site-container{padding-left:18px;padding-right:18px
}
}
@media (min-width:425px) and (max-width:767px){
.site-container{padding-left:22px;padding-right:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.site-container{padding-left:28px;padding-right:28px
}
}
@media (min-width:1024px){
.site-container{padding-left:32px;padding-right:32px
}
}
@media (min-width:425px) and (max-width:767px){
.site-container{max-width:425px
}
}
footer{background-color:#383838;color:#fff
}
@media (max-width:424px){
footer{height:80px;line-height:80px;margin-top:80px
}
}
@media (min-width:425px) and (max-width:767px){
footer{height:80px;line-height:80px;margin-top:80px
}
}
@media (min-width:768px) and (max-width:1023px){
footer{height:90px;line-height:90px;margin-top:90px
}
}
@media (min-width:1024px){
footer{height:90px;line-height:90px;margin-top:90px
}
}
@media (max-width:424px){
footer .author{margin-right:18px
}
}
@media (min-width:425px) and (max-width:767px){
footer .author{margin-right:22px
}
}
@media (min-width:768px) and (max-width:1023px){
footer .author{margin-right:28px
}
}
@media (min-width:1024px){
footer .author{margin-right:32px
}
}
@media (max-width:424px){
footer .author{margin-left:3px
}
}
@media (min-width:425px) and (max-width:767px){
footer .author{margin-left:3px
}
}
@media (min-width:768px) and (max-width:1023px){
footer .author{margin-left:4px
}
}
@media (min-width:1024px){
footer .author{margin-left:5px
}
}
@media (max-width:424px){
footer .author{font-size:15px
}
}
footer .small-quill{vertical-align:middle
}
@media (max-width:424px){
footer .small-quill{height:35px;width:35px
}
}
@media (min-width:425px) and (max-width:767px){
footer .small-quill{height:35px;width:35px
}
}
@media (min-width:768px) and (max-width:1023px){
footer .small-quill{height:40px;width:40px
}
}
@media (min-width:1024px){
footer .small-quill{height:40px;width:40px
}
}
footer .small-quill>.outer-stem{stroke:#fff
}
footer .small-quill>.feather{fill:#fff
}
footer .small-quill>.inner-stem{fill:#131313
}
footer .octicon{color:#fff;vertical-align:middle;padding-left:1px
}
@media (max-width:424px){
footer .octicon{height:50px;width:50px
}
}
@media (min-width:425px) and (max-width:767px){
footer .octicon{height:50px;width:50px
}
}
@media (min-width:768px) and (max-width:1023px){
footer .octicon{height:55px;width:55px
}
}
@media (min-width:1024px){
footer .octicon{height:55px;width:55px
}
}
@media (max-width:340px){
footer .small-quill{margin-left:-8px
}
footer .author{margin-right:10px
}
}
@media (max-width:424px){
h3,h4,h5{margin-top:18px
}
}
@media (min-width:425px) and (max-width:767px){
h3,h4,h5{margin-top:22px
}
}
@media (min-width:768px) and (max-width:1023px){
h3,h4,h5{margin-top:28px
}
}
@media (min-width:1024px){
h3,h4,h5{margin-top:32px
}
}
h1,h2,h3,h4{font-family:'Source Serif Pro',serif;font-weight:600
}
h5{font-size:16px;font-weight:600
}
@media (max-width:424px){
h5{margin-top:18px
}
}
@media (min-width:425px) and (max-width:767px){
h5{margin-top:22px
}
}
@media (min-width:768px) and (max-width:1023px){
h5{margin-top:28px
}
}
@media (min-width:1024px){
h5{margin-top:32px
}
}
h1{margin-top:0
}
@media (max-width:424px){
h1{font-size:32px
}
}
@media (min-width:425px) and (max-width:767px){
h1{font-size:38px
}
}
@media (min-width:768px) and (max-width:1023px){
h1{font-size:44px
}
}
@media (min-width:1024px){
h1{font-size:48px
}
}
@media (max-width:424px){
h2{font-size:26px
}
}
@media (min-width:425px) and (max-width:767px){
h2{font-size:29px
}
}
@media (min-width:768px) and (max-width:1023px){
h2{font-size:32px
}
}
@media (min-width:1024px){
h2{font-size:32px
}
}
@media (max-width:424px){
h3{font-size:22px
}
}
@media (min-width:425px) and (max-width:767px){
h3{font-size:22px
}
}
@media (min-width:768px) and (max-width:1023px){
h3{font-size:24px
}
}
@media (min-width:1024px){
h3{font-size:26px
}
}
h4{font-size:20px
}
h1,h2,h3,h4,h5,h6{margin-bottom:0
}
p{margin-bottom:0
}
@media (max-width:424px){
p{margin-top:12px
}
}
@media (min-width:425px) and (max-width:767px){
p{margin-top:13px
}
}
@media (min-width:768px) and (max-width:1023px){
p{margin-top:13px
}
}
@media (min-width:1024px){
p{margin-top:14px
}
}
p.sidenote{font-style:italic
}
header{overflow:hidden
}
@media (max-width:424px){
header{margin-top:18px;padding-bottom:18px
}
}
@media (min-width:425px) and (max-width:767px){
header{margin-top:22px;padding-bottom:22px
}
}
@media (min-width:768px) and (max-width:1023px){
header{margin-top:28px;padding-bottom:28px
}
}
@media (min-width:1024px){
header{margin-top:32px;padding-bottom:32px
}
}
header a+a{margin-left:20px
}
header .logo{display:block
}
@media (max-width:424px){
header .logo{margin-bottom:22px
}
}
@media (min-width:425px) and (max-width:767px){
header .logo{margin-bottom:26px
}
}
@media (min-width:768px) and (max-width:1023px){
header .logo{margin-bottom:32px
}
}
@media (min-width:1024px){
header .logo{margin-bottom:36px
}
}
@media (max-width:424px){
header .logo{width:300px
}
}
@media (min-width:425px) and (max-width:767px){
header .logo{width:340px
}
}
@media (min-width:768px) and (max-width:1023px){
header .logo{width:380px
}
}
@media (min-width:1024px){
header .logo{width:420px
}
}
ul:not(.bulleted){list-style:none;padding-left:0;margin-top:0;margin-bottom:0
}
ul.bulleted{list-style-type:disc;padding-left:20px
}
@media (max-width:424px){
ul.bulleted>li{margin-top:3px
}
}
@media (min-width:425px) and (max-width:767px){
ul.bulleted>li{margin-top:3px
}
}
@media (min-width:768px) and (max-width:1023px){
ul.bulleted>li{margin-top:4px
}
}
@media (min-width:1024px){
ul.bulleted>li{margin-top:5px
}
}
.feather-icon{height:24px;width:24px
}
button{cursor:pointer
}
button.link{background-color:transparent;text-decoration:underline
}
button.link p{text-decoration:underline
}
a,a:visited{color:currentColor
}
.dont-wrap{white-space:nowrap
}
svg.emoji{display:inline-block;margin-top:-1px;vertical-align:middle
}
span.important{font-weight:600
}
dl>dt{font-weight:700
}
@media (max-width:424px){
dl>dt{margin-bottom:3px
}
}
@media (min-width:425px) and (max-width:767px){
dl>dt{margin-bottom:3px
}
}
@media (min-width:768px) and (max-width:1023px){
dl>dt{margin-bottom:4px
}
}
@media (min-width:1024px){
dl>dt{margin-bottom:5px
}
}
@media (max-width:424px){
dl>dd{margin-left:18px
}
}
@media (min-width:425px) and (max-width:767px){
dl>dd{margin-left:22px
}
}
@media (min-width:768px) and (max-width:1023px){
dl>dd{margin-left:28px
}
}
@media (min-width:1024px){
dl>dd{margin-left:32px
}
}
dl>dd>p:first-child{margin-top:0
}
@media (max-width:424px){
dl>dd+dt{margin-top:14px
}
}
@media (min-width:425px) and (max-width:767px){
dl>dd+dt{margin-top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
dl>dd+dt{margin-top:18px
}
}
@media (min-width:1024px){
dl>dd+dt{margin-top:20px
}
}
.removed{display:none!important
}
@media (min-width:768px){
.phones-and-smaller{display:none!important
}
}
@media (max-width:767px){
.tablets-and-larger{display:none!important
}
}
@media (max-width:424px){
.phones-and-larger{display:none!important
}
}
@media (min-width:425px){
.small-phones{display:none!important
}
}