
.lightbox{display:none;position:relative
}
.lightbox.active{display:block
}
.lightbox>.backlight{background-color:#fff;height:100%;left:0;opacity:0;position:fixed;top:0;width:100%;z-index:3
}
.lightbox>.modal{-webkit-box-shadow:0 3px 6px #aaa;box-shadow:0 3px 6px #aaa;background-color:#fff;border:1px solid #e6e6e6;border-radius:10px;left:0;margin-left:auto;margin-right:auto;max-width:512px;opacity:0;position:absolute;right:0;z-index:4
}
@media (max-width:424px){
.lightbox>.modal{padding-top:14px;padding-bottom:14px;padding-left:14px
}
}
@media (min-width:425px) and (max-width:767px){
.lightbox>.modal{padding-top:16px;padding-bottom:16px;padding-left:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.lightbox>.modal{padding-top:18px;padding-bottom:18px;padding-left:18px
}
}
@media (min-width:1024px){
.lightbox>.modal{padding-top:20px;padding-bottom:20px;padding-left:20px
}
}
@media (min-width:425px){
.lightbox>.modal{padding-right:65px
}
}
@media (max-width:424px){
.lightbox>.modal{padding-right:40px
}
}
.lightbox>.modal .close{position:absolute
}
@media (max-width:424px){
.lightbox>.modal .close{top:12px;right:12px
}
}
@media (min-width:425px) and (max-width:767px){
.lightbox>.modal .close{top:13px;right:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.lightbox>.modal .close{top:13px;right:13px
}
}
@media (min-width:1024px){
.lightbox>.modal .close{top:14px;right:14px
}
}
.lightbox>.modal .content{border-left-width:3px;border-left-style:solid
}
@media (max-width:424px){
.lightbox>.modal .content{padding-left:18px
}
}
@media (min-width:425px) and (max-width:767px){
.lightbox>.modal .content{padding-left:21px
}
}
@media (min-width:768px) and (max-width:1023px){
.lightbox>.modal .content{padding-left:24px
}
}
@media (min-width:1024px){
.lightbox>.modal .content{padding-left:24px
}
}
@media (max-width:424px){
.lightbox>.modal .content{padding-top:12px;padding-bottom:12px
}
}
@media (min-width:425px) and (max-width:767px){
.lightbox>.modal .content{padding-top:13px;padding-bottom:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.lightbox>.modal .content{padding-top:13px;padding-bottom:13px
}
}
@media (min-width:1024px){
.lightbox>.modal .content{padding-top:14px;padding-bottom:14px
}
}
.lightbox>.modal .content.error{border-left-color:#de5858
}
.lightbox>.modal .content.warn{border-left-color:#d49300
}
.lightbox>.modal .content.info{border-left-color:#4c7eaf
}
.lightbox>.modal .content>:first-child{margin-top:0
}
.lightbox>.modal .content>:last-child{margin-bottom:0
}