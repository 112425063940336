
@media (max-width:424px){
.emoji.party{margin-left:12px
}
}
@media (min-width:425px) and (max-width:767px){
.emoji.party{margin-left:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.emoji.party{margin-left:13px
}
}
@media (min-width:1024px){
.emoji.party{margin-left:14px
}
}
.emoji.party .color1{color:#3d496f
}
.emoji.party .color2{color:#a44242
}
.emoji.party .color3{color:#ff9b46
}
.emoji.party .color4{color:#4caf50
}
.emoji.party .color5{color:#8c4f84
}
.emoji.party .cone-color{color:#2b334e
}