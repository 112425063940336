
.my-form .loading-check{height:48px;width:48px
}
@media (max-width:424px){
.my-form .loading-check{margin-left:14px
}
}
@media (min-width:425px) and (max-width:767px){
.my-form .loading-check{margin-left:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-form .loading-check{margin-left:18px
}
}
@media (min-width:1024px){
.my-form .loading-check{margin-left:20px
}
}
.my-form .submit{vertical-align:top
}
.my-form .failure-link{border:1px solid transparent
}
@media (min-width:768px){
.my-form .failure-link{display:inline-block
}
}
@media (min-width:768px) and (min-width:768px) and (max-width:1023px){
.my-form .failure-link{margin-top:36px
}
}
@media (min-width:768px) and (min-width:1024px){
.my-form .failure-link{margin-top:42px
}
}
@media (min-width:768px){
.my-form .failure-link>.wrapper{height:18px;line-height:18px;margin-top:-8px
}
}
@media (max-width:767px){
.my-form .failure-link{display:-webkit-box;display:-ms-flexbox;display:flex
}
}
@media (max-width:767px) and (max-width:424px){
.my-form .failure-link{margin-top:18px
}
}
@media (max-width:767px) and (min-width:425px) and (max-width:767px){
.my-form .failure-link{margin-top:22px
}
}
@media (max-width:767px) and (max-width:424px){
.my-form .failure-link{padding:12px
}
}
@media (max-width:767px) and (min-width:425px) and (max-width:767px){
.my-form .failure-link{padding:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-form .failure-link{padding:18px 28px
}
}
@media (min-width:1024px){
.my-form .failure-link{padding:20px 32px
}
}