
svg.arrow-circle{-webkit-tap-highlight-color:transparent;color:#fff;-webkit-filter:drop-shadow(0 2px 2px #aaa);filter:drop-shadow(0 2px 2px #aaa);overflow:visible
}
svg.arrow-circle>circle{fill:#4c7eaf
}
svg.arrow-circle>circle:first-child{display:none
}
svg.arrow-circle.pulsate>circle:first-child{display:inline;-webkit-animation:pulsateAnimation_arrowCircle 1.4s infinite;animation:pulsateAnimation_arrowCircle 1.4s infinite;-webkit-transform-origin:center;transform-origin:center;-webkit-transition-property:opacity,-webkit-transform;transition-property:opacity,-webkit-transform;transition-property:transform,opacity;transition-property:transform,opacity,-webkit-transform;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715)
}
@-webkit-keyframes pulsateAnimation_arrowCircle{
from{opacity:.8;-webkit-transform:scale(1);transform:scale(1)
}
to{opacity:0;-webkit-transform:scale(1.9);transform:scale(1.9)
}
}
@keyframes pulsateAnimation_arrowCircle{
from{opacity:.8;-webkit-transform:scale(1);transform:scale(1)
}
to{opacity:0;-webkit-transform:scale(1.9);transform:scale(1.9)
}
}