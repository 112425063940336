
.loading-check{border-radius:50%;display:inline-block;vertical-align:middle
}
.loading-check.success{-webkit-animation:fill .4s cubic-bezier(.65,0,.45,1) .4s forwards;animation:fill .4s cubic-bezier(.65,0,.45,1) .4s forwards
}
.loading-check.success>path{display:inline-block
}
.loading-check.loading>circle{-webkit-animation:stroke 1s linear forwards;animation:stroke 1s linear forwards;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;stroke:#4caf50;stroke-dasharray:25,51;stroke-dashoffset:76;stroke-width:2
}
.loading-check>circle{fill:none
}
.loading-check>path{-webkit-animation:stroke .3s cubic-bezier(.65,0,.45,1) .8s forwards;animation:stroke .3s cubic-bezier(.65,0,.45,1) .8s forwards;display:none;stroke:#fff;stroke-dasharray:48;stroke-dashoffset:48;stroke-width:1.2;-webkit-transform-origin:50% 50%;transform-origin:50% 50%
}
@-webkit-keyframes stroke{
100%{stroke-dashoffset:0
}
}
@keyframes stroke{
100%{stroke-dashoffset:0
}
}
@-webkit-keyframes fill{
0%{-webkit-box-shadow:inset 0 0 0 0 #4caf50;box-shadow:inset 0 0 0 0 #4caf50
}
100%{-webkit-box-shadow:inset 0 0 0 30px #4caf50;box-shadow:inset 0 0 0 30px #4caf50
}
}
@keyframes fill{
0%{-webkit-box-shadow:inset 0 0 0 0 #4caf50;box-shadow:inset 0 0 0 0 #4caf50
}
100%{-webkit-box-shadow:inset 0 0 0 30px #4caf50;box-shadow:inset 0 0 0 30px #4caf50
}
}