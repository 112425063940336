
@media (max-width:424px){
.prior-guesses>li .frown,.prior-guesses>li button.clock{margin-left:12px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li .frown,.prior-guesses>li button.clock{margin-left:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li .frown,.prior-guesses>li button.clock{margin-left:13px
}
}
@media (min-width:1024px){
.prior-guesses>li .frown,.prior-guesses>li button.clock{margin-left:14px
}
}
.prior-guesses>li>.letters{display:inline-block;position:relative
}
.prior-guesses>li>.letters>li{display:inline-block
}
@media (max-width:424px){
.prior-guesses>li>.letters>li{font-size:16px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li>.letters>li{font-size:17px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li>.letters>li{font-size:17px
}
}
@media (min-width:1024px){
.prior-guesses>li>.letters>li{font-size:18px
}
}
.prior-guesses>li>.letters>li.chosen>button{border-color:#ff8d00
}
.prior-guesses>li>.letters>li.chosen>span{background-color:#dcdcdc;border-radius:200px;display:inline-block;text-align:center
}
@media (max-width:424px){
.prior-guesses>li>.letters>li.chosen>span{line-height:34px;height:34px;width:34px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li>.letters>li.chosen>span{line-height:36px;height:36px;width:36px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li>.letters>li.chosen>span{line-height:38px;height:38px;width:38px
}
}
@media (min-width:1024px){
.prior-guesses>li>.letters>li.chosen>span{line-height:40px;height:40px;width:40px
}
}
@media (max-width:424px){
.prior-guesses>li>.letters>li.choosable:not(:first-child),.prior-guesses>li>.letters>li.chosen:not(:first-child){margin-left:3px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li>.letters>li.choosable:not(:first-child),.prior-guesses>li>.letters>li.chosen:not(:first-child){margin-left:3px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li>.letters>li.choosable:not(:first-child),.prior-guesses>li>.letters>li.chosen:not(:first-child){margin-left:4px
}
}
@media (min-width:1024px){
.prior-guesses>li>.letters>li.choosable:not(:first-child),.prior-guesses>li>.letters>li.chosen:not(:first-child){margin-left:5px
}
}
@media (max-width:424px){
.prior-guesses>li>.letters>li.choosable:not(:last-child),.prior-guesses>li>.letters>li.chosen:not(:last-child){margin-right:3px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li>.letters>li.choosable:not(:last-child),.prior-guesses>li>.letters>li.chosen:not(:last-child){margin-right:3px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li>.letters>li.choosable:not(:last-child),.prior-guesses>li>.letters>li.chosen:not(:last-child){margin-right:4px
}
}
@media (min-width:1024px){
.prior-guesses>li>.letters>li.choosable:not(:last-child),.prior-guesses>li>.letters>li.chosen:not(:last-child){margin-right:5px
}
}
.prior-guesses>li>.letters>li.cross-out-line{display:block;height:100%;left:0;position:absolute;top:0;width:100%
}
.prior-guesses>li>.letters>li.cross-out-line>svg>line{stroke:#131313
}
.prior-guesses>li>.letters>li>button,.prior-guesses>li>.letters>li>span{font-family:Hack,monospace;vertical-align:middle
}
.prior-guesses>li>.letters>li>button{-webkit-box-shadow:0 2px 6px #aaa;box-shadow:0 2px 6px #aaa;background-color:#ffe4a7;border:2px solid #ffe4a7;border-radius:200px;padding:0;-webkit-transition-duration:.15s;transition-duration:.15s;-webkit-transition-property:background-color,border-color,-webkit-box-shadow;transition-property:background-color,border-color,-webkit-box-shadow;transition-property:background-color,border-color,box-shadow;transition-property:background-color,border-color,box-shadow,-webkit-box-shadow;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715)
}
.prior-guesses>li>.letters>li>button:focus,.prior-guesses>li>.letters>li>button[focus-within]{-webkit-box-shadow:0 2px 6px #919191;box-shadow:0 2px 6px #919191
}
.prior-guesses>li>.letters>li>button:focus,.prior-guesses>li>.letters>li>button:focus-within{-webkit-box-shadow:0 2px 6px #919191;box-shadow:0 2px 6px #919191
}
@media (max-width:424px){
.prior-guesses>li>.letters>li>button{height:34px;width:34px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li>.letters>li>button{height:36px;width:36px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li>.letters>li>button{height:38px;width:38px
}
}
@media (min-width:1024px){
.prior-guesses>li>.letters>li>button{height:40px;width:40px
}
}
@media (max-width:424px){
.prior-guesses>li>.letters>li>button{line-height:30px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li>.letters>li>button{line-height:32px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li>.letters>li>button{line-height:34px
}
}
@media (min-width:1024px){
.prior-guesses>li>.letters>li>button{line-height:36px
}
}
@media (max-width:424px){
.prior-guesses>li>.letters>li>button{margin-left:3px;margin-right:3px
}
}
@media (min-width:425px) and (max-width:767px){
.prior-guesses>li>.letters>li>button{margin-left:3px;margin-right:3px
}
}
@media (min-width:768px) and (max-width:1023px){
.prior-guesses>li>.letters>li>button{margin-left:4px;margin-right:4px
}
}
@media (min-width:1024px){
.prior-guesses>li>.letters>li>button{margin-left:5px;margin-right:5px
}
}