
.attention-circle{background-color:#ff8d00;border-color:transparent;border-radius:100px;display:none;height:14px;position:absolute;right:-16px;top:-8px;width:14px
}
.attention-circle.animate_shown{display:inline-block
}
.attention-circle>.pulsating-circle{-webkit-animation:pulsateAnimation_attentionCircle 1.4s infinite;animation:pulsateAnimation_attentionCircle 1.4s infinite;background-color:#ff8d00;border-color:transparent;border-radius:100px;display:inline-block;height:14px;-webkit-transform-origin:center;transform-origin:center;-webkit-transition-property:opacity,-webkit-transform;transition-property:opacity,-webkit-transform;transition-property:transform,opacity;transition-property:transform,opacity,-webkit-transform;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715);width:14px;position:relative;top:-6px
}
@-webkit-keyframes pulsateAnimation_attentionCircle{
from{opacity:.8;-webkit-transform:scale(1);transform:scale(1)
}
to{opacity:0;-webkit-transform:scale(2.4);transform:scale(2.4)
}
}
@keyframes pulsateAnimation_attentionCircle{
from{opacity:.8;-webkit-transform:scale(1);transform:scale(1)
}
to{opacity:0;-webkit-transform:scale(2.4);transform:scale(2.4)
}
}