
.default-lightbox{text-align:left
}
@media (max-width:424px){
.default-lightbox p+ul{margin-top:6px
}
}
@media (min-width:425px) and (max-width:767px){
.default-lightbox p+ul{margin-top:7px
}
}
@media (min-width:768px) and (max-width:1023px){
.default-lightbox p+ul{margin-top:7px
}
}
@media (min-width:1024px){
.default-lightbox p+ul{margin-top:8px
}
}