
@media (max-width:767px){
#app.game{text-align:center
}
}
@media (max-width:767px) and (max-width:424px){
#app.game>header{padding-bottom:14px
}
}
@media (max-width:767px) and (min-width:425px) and (max-width:767px){
#app.game>header{padding-bottom:16px
}
}
@media (max-width:767px){
#app.game>header .logo{margin-left:auto;margin-right:auto;position:relative;right:-8px
}
#app.game>main>.site-container{max-width:unset;padding-left:0;padding-right:0
}
#app.game>main .view.room>.wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1
}
#app.game>footer{margin-top:0
}
}
.sub-view.game{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1
}
.sub-view.game .tbd{font-style:italic
}
.sub-view.game .guide-finished,.sub-view.game .guide>*{background-color:#e6e6e6;vertical-align:top;white-space:normal;width:100%
}
@media (max-width:424px){
.sub-view.game .guide-finished,.sub-view.game .guide>*{padding:18px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game .guide-finished,.sub-view.game .guide>*{padding:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game .guide-finished,.sub-view.game .guide>*{padding:28px
}
}
@media (min-width:1024px){
.sub-view.game .guide-finished,.sub-view.game .guide>*{padding:32px
}
}
.sub-view.game .game-over-message{background-color:#e6e6e6
}
@media (max-width:424px){
.sub-view.game .game-over-message{padding:18px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game .game-over-message{padding:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game .game-over-message{padding:28px
}
}
@media (min-width:1024px){
.sub-view.game .game-over-message{padding:32px
}
}
.sub-view.game .game-over-message>:first-child{margin-top:0
}
@media (max-width:767px){
.sub-view.game .guide-finished,.sub-view.game .guide>*{margin-bottom:40px
}
}
@media (max-width:767px) and (max-width:424px){
.sub-view.game .guide-finished,.sub-view.game .guide>*{margin-top:18px
}
}
@media (max-width:767px) and (min-width:425px) and (max-width:767px){
.sub-view.game .guide-finished,.sub-view.game .guide>*{margin-top:22px
}
}
@media (max-width:767px){
.sub-view.game>.board>ul.player-view:not(.has-guide){padding-bottom:80px
}
.sub-view.game.game-over{padding-bottom:40px
}
.sub-view.game.game-over>.board.phones-and-smaller>ul.player-view{padding-bottom:40px
}
}
@media (min-width:768px){
.sub-view.game .game-over-message,.sub-view.game .guide-finished,.sub-view.game .guide>*{border-radius:4px;max-width:640px
}
}
@media (min-width:768px) and (min-width:768px) and (max-width:1023px){
.sub-view.game>.board>.column{margin-top:18px
}
}
@media (min-width:768px) and (min-width:1024px){
.sub-view.game>.board>.column{margin-top:20px
}
}
.sub-view.game>.board .guide p:first-child{margin-top:0
}
.sub-view.game>.board>.column{display:inline-block;vertical-align:top;width:320px
}
@media (max-width:424px){
.sub-view.game>.board>.column{padding-bottom:28px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board>.column{padding-bottom:30px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board>.column{padding-bottom:36px
}
}
@media (min-width:1024px){
.sub-view.game>.board>.column{padding-bottom:42px
}
}
.sub-view.game>.board>.column>h3{margin-top:0;overflow:hidden;text-overflow:ellipsis
}
@media (max-width:424px){
.sub-view.game>.board>.column>h3{font-size:19px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board>.column>h3{font-size:21px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board>.column>h3{font-size:23px
}
}
@media (min-width:1024px){
.sub-view.game>.board>.column>h3{font-size:25px
}
}
@media (max-width:424px){
.sub-view.game>.board>.column>ul{margin-top:6px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board>.column>ul{margin-top:7px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board>.column>ul{margin-top:7px
}
}
@media (min-width:1024px){
.sub-view.game>.board>.column>ul{margin-top:8px
}
}
.sub-view.game>.board>.column>ul>li:not(.enter-guess){vertical-align:top
}
@media (max-width:424px){
.sub-view.game>.board>.column>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board>.column>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board>.column>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}
@media (min-width:1024px){
.sub-view.game>.board>.column>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}
@media (max-width:424px){
.sub-view.game>.board>.column+.column{margin-left:14px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board>.column+.column{margin-left:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board>.column+.column{margin-left:18px
}
}
@media (min-width:1024px){
.sub-view.game>.board>.column+.column{margin-left:20px
}
}
.sub-view.game>.board.phones-and-smaller{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;text-align:center
}
.sub-view.game>.board.phones-and-smaller>.sticky-header{-ms-flex-item-align:start;align-self:start;background-color:#fff;-webkit-box-shadow:0 0 0 transparent;box-shadow:0 0 0 transparent;position:-webkit-sticky;position:sticky;top:0;-webkit-transition:-webkit-box-shadow .3s cubic-bezier(.47,0,.745,.715);transition:-webkit-box-shadow .3s cubic-bezier(.47,0,.745,.715);transition:box-shadow .3s cubic-bezier(.47,0,.745,.715);transition:box-shadow .3s cubic-bezier(.47,0,.745,.715),-webkit-box-shadow .3s cubic-bezier(.47,0,.745,.715);width:100%;z-index:2
}
@media (max-width:424px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-bottom:14px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-bottom:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-bottom:18px
}
}
@media (min-width:1024px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-bottom:20px
}
}
@media (max-width:424px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-top:12px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-top:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-top:13px
}
}
@media (min-width:1024px){
.sub-view.game>.board.phones-and-smaller>.sticky-header{padding-top:14px
}
}
.sub-view.game>.board.phones-and-smaller>.sticky-header.stuck{-webkit-box-shadow:0 2px 6px #aaa;box-shadow:0 2px 6px #aaa
}
.sub-view.game>.board.phones-and-smaller>.sticky-header>.status{display:block
}
.sub-view.game>.board.phones-and-smaller>.sticky-header>.wrapper{max-width:425px;margin-left:auto;margin-right:auto;position:relative
}
.sub-view.game>.board.phones-and-smaller>.sticky-header .display-names{display:block;overflow:hidden;white-space:nowrap;z-index:0
}
@media (max-width:424px){
.sub-view.game>.board.phones-and-smaller>.sticky-header .display-names{margin-top:14px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board.phones-and-smaller>.sticky-header .display-names{margin-top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board.phones-and-smaller>.sticky-header .display-names{margin-top:18px
}
}
@media (min-width:1024px){
.sub-view.game>.board.phones-and-smaller>.sticky-header .display-names{margin-top:20px
}
}
.sub-view.game>.board.phones-and-smaller>.sticky-header .display-names>h4{display:inline-block;font-size:22px;margin-top:0;width:100%
}
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle{bottom:0;position:absolute;z-index:1
}
@media (max-width:424px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.left{left:18px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.left{left:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.left{left:28px
}
}
@media (min-width:1024px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.left{left:32px
}
}
@media (max-width:424px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.right{right:18px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.right{right:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.right{right:28px
}
}
@media (min-width:1024px){
.sub-view.game>.board.phones-and-smaller>.sticky-header button.arrow-circle.right{right:32px
}
}
.sub-view.game>.board.phones-and-smaller>ul.player-view{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;overflow:hidden;white-space:nowrap;z-index:1
}
.sub-view.game>.board.phones-and-smaller>ul.player-view>li{display:inline-block;vertical-align:top;width:100%
}
.sub-view.game>.board.phones-and-smaller>ul.player-view>li>ul>li:not(.enter-guess){vertical-align:top
}
@media (max-width:424px){
.sub-view.game>.board.phones-and-smaller>ul.player-view>li>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.game>.board.phones-and-smaller>ul.player-view>li>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.game>.board.phones-and-smaller>ul.player-view>li>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}
@media (min-width:1024px){
.sub-view.game>.board.phones-and-smaller>ul.player-view>li>ul>li:not(.enter-guess){height:56px;line-height:56px
}
}