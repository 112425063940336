
@media (max-width:424px){
.view.create-a-room>form{margin-top:14px
}
}
@media (min-width:425px) and (max-width:767px){
.view.create-a-room>form{margin-top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.view.create-a-room>form{margin-top:18px
}
}
@media (min-width:1024px){
.view.create-a-room>form{margin-top:20px
}
}
@media (max-width:424px){
.view.create-a-room .info-after-submit h3{margin-top:18px
}
}
@media (min-width:425px) and (max-width:767px){
.view.create-a-room .info-after-submit h3{margin-top:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.view.create-a-room .info-after-submit h3{margin-top:28px
}
}
@media (min-width:1024px){
.view.create-a-room .info-after-submit h3{margin-top:32px
}
}
@media (max-width:424px){
.view.create-a-room .info-after-submit h3{margin-bottom:12px
}
}
@media (min-width:425px) and (max-width:767px){
.view.create-a-room .info-after-submit h3{margin-bottom:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.view.create-a-room .info-after-submit h3{margin-bottom:13px
}
}
@media (min-width:1024px){
.view.create-a-room .info-after-submit h3{margin-bottom:14px
}
}
@media (max-width:767px){
.view.create-a-room .more-info{max-width:450px
}
}
@media (min-width:768px){
.view.create-a-room .more-info{width:450px
}
.view.create-a-room .my-form label{width:150px
}
}