
.failure-link{-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#a44242;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row
}
.failure-link>*{display:inline-block;vertical-align:middle
}
.failure-link>p{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;margin-top:0
}
@media (max-width:424px){
.failure-link>.wrapper{margin-left:6px
}
}
@media (min-width:425px) and (max-width:767px){
.failure-link>.wrapper{margin-left:7px
}
}
@media (min-width:768px) and (max-width:1023px){
.failure-link>.wrapper{margin-left:7px
}
}
@media (min-width:1024px){
.failure-link>.wrapper{margin-left:8px
}
}
.failure-link::before{background-color:transparent;bottom:0;-webkit-box-shadow:0 0 0 transparent;box-shadow:0 0 0 transparent;content:'';display:inline-block;left:0;right:0;position:absolute;top:0;-webkit-transition-duration:.15s;transition-duration:.15s;-webkit-transition-property:background-color,-webkit-box-shadow;transition-property:background-color,-webkit-box-shadow;transition-property:background-color,box-shadow;transition-property:background-color,box-shadow,-webkit-box-shadow;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715);-webkit-transition-delay:50ms;transition-delay:50ms
}
.failure-link:focus::before{bottom:0;-webkit-box-shadow:0 0 5px #3d496f;box-shadow:0 0 5px #3d496f;content:'';display:inline-block;position:absolute;left:0;right:0;top:0
}