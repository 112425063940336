
h5.status{font-style:italic;font-weight:500;margin-top:0
}
@media (max-width:424px){
h5.status{height:30px;line-height:30px
}
}
@media (min-width:425px) and (max-width:767px){
h5.status{height:30px;line-height:30px
}
}
@media (min-width:768px) and (max-width:1023px){
h5.status{height:30px;line-height:30px
}
}
@media (min-width:1024px){
h5.status{height:30px;line-height:30px
}
}
@media (min-width:768px){
h5.status{width:640px
}
}
@media (max-width:424px){
h5.status button.clock{margin-right:6px
}
}
@media (min-width:425px) and (max-width:767px){
h5.status button.clock{margin-right:7px
}
}
@media (min-width:768px) and (max-width:1023px){
h5.status button.clock{margin-right:7px
}
}
@media (min-width:1024px){
h5.status button.clock{margin-right:8px
}
}
h5.status button.help{vertical-align:middle;margin-top:-4px
}
@media (max-width:424px){
h5.status button.help{margin-right:6px
}
}
@media (min-width:425px) and (max-width:767px){
h5.status button.help{margin-right:7px
}
}
@media (min-width:768px) and (max-width:1023px){
h5.status button.help{margin-right:7px
}
}
@media (min-width:1024px){
h5.status button.help{margin-right:8px
}
}
h5.status button.help .help-circle{color:#fff;fill:#4c7eaf
}
h5.status .message{position:relative
}