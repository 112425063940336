
.sub-view.introduce-guide{display:block!important;position:relative
}
@media (min-width:768px){
.sub-view.introduce-guide{max-width:50%
}
}
.sub-view.introduce-guide button.no-thanks{line-height:1.4
}
@media (max-width:424px){
.sub-view.introduce-guide button.no-thanks{margin-left:18px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.introduce-guide button.no-thanks{margin-left:22px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.introduce-guide button.no-thanks{margin-left:28px
}
}
@media (min-width:1024px){
.sub-view.introduce-guide button.no-thanks{margin-left:32px
}
}