
@media (max-width:424px){
#app.first-time-desktop>header{padding-bottom:28px
}
}
@media (min-width:425px) and (max-width:767px){
#app.first-time-desktop>header{padding-bottom:30px
}
}
@media (min-width:768px) and (max-width:1023px){
#app.first-time-desktop>header{padding-bottom:36px
}
}
@media (min-width:1024px){
#app.first-time-desktop>header{padding-bottom:42px
}
}
#app.first-time-desktop .view.room>.wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1
}
#app.first-time-desktop>footer{margin-top:0
}
.sub-view.first-time-desktop{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-bottom:80px
}
.sub-view.first-time-desktop>.steps{display:block;max-width:50%
}
@media (max-width:424px){
.sub-view.first-time-desktop>.steps:not(.step-0):not(.step-5){margin-top:14px
}
}
@media (min-width:425px) and (max-width:767px){
.sub-view.first-time-desktop>.steps:not(.step-0):not(.step-5){margin-top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.sub-view.first-time-desktop>.steps:not(.step-0):not(.step-5){margin-top:18px
}
}
@media (min-width:1024px){
.sub-view.first-time-desktop>.steps:not(.step-0):not(.step-5){margin-top:20px
}
}
.sub-view.first-time-desktop>.steps>li:not(:first-child):not(.animate_shown){display:none
}