
.more-info{-webkit-box-shadow:0 3px 6px #aaa;box-shadow:0 3px 6px #aaa;border:1px solid #4c7eaf;border-radius:4px;overflow:hidden;-webkit-transition-property:border-color;transition-property:border-color;-webkit-transition-duration:.15s;transition-duration:.15s;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715)
}
@media (max-width:424px){
.more-info{margin-top:14px
}
}
@media (min-width:425px) and (max-width:767px){
.more-info{margin-top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.more-info{margin-top:18px
}
}
@media (min-width:1024px){
.more-info{margin-top:20px
}
}
.more-info:focus{border-color:#ff9b46;-webkit-box-shadow:0 0 10px #ff9b46;box-shadow:0 0 10px #ff9b46;outline:0
}
.more-info.expanded .summary{border-bottom:1px solid #aaa
}
.more-info .details{display:none
}
@media (max-width:424px){
.more-info .details{padding:12px
}
}
@media (min-width:425px) and (max-width:767px){
.more-info .details{padding:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.more-info .details{padding:13px
}
}
@media (min-width:1024px){
.more-info .details{padding:14px
}
}
.more-info .details>:first-child{margin-top:0
}
.more-info .details>:last-child{margin-bottom:0
}
.more-info .summary{cursor:pointer;display:block;position:relative;text-align:left;width:100%
}
@media (max-width:424px){
.more-info .summary{padding:12px
}
}
@media (min-width:425px) and (max-width:767px){
.more-info .summary{padding:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.more-info .summary{padding:13px
}
}
@media (min-width:1024px){
.more-info .summary{padding:14px
}
}
.more-info .summary>.help-circle{-webkit-filter:none;filter:none
}
.more-info .summary>*{display:inline-block;vertical-align:middle
}
.more-info .summary>p{margin:0
}
@media (max-width:424px){
.more-info .summary>p{margin-left:12px;margin-right:12px
}
}
@media (min-width:425px) and (max-width:767px){
.more-info .summary>p{margin-left:13px;margin-right:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.more-info .summary>p{margin-left:13px;margin-right:13px
}
}
@media (min-width:1024px){
.more-info .summary>p{margin-left:14px;margin-right:14px
}
}
.more-info .summary .chevron-down{color:#4c7eaf;position:absolute;right:0
}
@media (max-width:424px){
.more-info .summary .chevron-down{margin-right:12px
}
}
@media (min-width:425px) and (max-width:767px){
.more-info .summary .chevron-down{margin-right:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.more-info .summary .chevron-down{margin-right:13px
}
}
@media (min-width:1024px){
.more-info .summary .chevron-down{margin-right:14px
}
}