
.notification-wrapper{position:fixed;left:0;right:0;text-align:center;top:0;width:100%;z-index:4
}
.notification-wrapper .notification{-webkit-box-shadow:0 3px 6px #aaa;box-shadow:0 3px 6px #aaa;background-color:#de5858;border-radius:4px;color:#fff;display:none;font-weight:500;line-height:1.4;position:relative
}
@media (max-width:424px){
.notification-wrapper .notification{min-width:100%
}
}
@media (min-width:425px) and (max-width:767px){
.notification-wrapper .notification{min-width:70%
}
}
@media (min-width:768px) and (max-width:1023px){
.notification-wrapper .notification{min-width:500px
}
}
@media (min-width:1024px){
.notification-wrapper .notification{min-width:500px
}
}
@media (max-width:424px){
.notification-wrapper .notification{padding:12px
}
}
@media (min-width:425px) and (max-width:767px){
.notification-wrapper .notification{padding:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.notification-wrapper .notification{padding:13px
}
}
@media (min-width:1024px){
.notification-wrapper .notification{padding:14px
}
}
.notification-wrapper .notification p{margin-top:0;text-align:center
}
.notification-wrapper .notification>.close-x{position:absolute
}
@media (max-width:424px){
.notification-wrapper .notification>.close-x{right:15px;top:15px
}
}
@media (min-width:425px) and (max-width:767px){
.notification-wrapper .notification>.close-x{right:16px;top:16px
}
}
@media (min-width:768px) and (max-width:1023px){
.notification-wrapper .notification>.close-x{right:16px;top:16px
}
}
@media (min-width:1024px){
.notification-wrapper .notification>.close-x{right:17px;top:17px
}
}
@media (max-width:424px){
.notification-wrapper .notification>.close-x{height:12px;width:12px
}
}
@media (max-width:424px){
.notification-wrapper .notification{font-size:14px
}
}