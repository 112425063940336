
.my-text-input button.help{vertical-align:middle;margin-top:-4px
}
@media (max-width:424px){
.my-text-input button.help{margin-right:12px
}
}
@media (min-width:425px) and (max-width:767px){
.my-text-input button.help{margin-right:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-text-input button.help{margin-right:13px
}
}
@media (min-width:1024px){
.my-text-input button.help{margin-right:14px
}
}
.my-text-input button.help .help-circle{color:#fff;fill:#4c7eaf
}
.my-text-input.has-inline-submit .input-wrapper{margin-top:0
}
.my-text-input>label{cursor:pointer
}
.my-text-input.readonly>label{cursor:text
}
.my-text-input.readonly>.input-wrapper{border-color:transparent
}
.my-text-input.readonly>.input-wrapper>input:not([type]):disabled,.my-text-input.readonly>.input-wrapper>input[type=email]:disabled,.my-text-input.readonly>.input-wrapper>input[type=text]:disabled{background-color:transparent;color:currentColor;cursor:text
}
.my-text-input>.input-wrapper{border:1px solid #939393;border-radius:4px;-webkit-box-shadow:0 0 0 transparent;box-shadow:0 0 0 transparent;-webkit-transition-duration:.15s;transition-duration:.15s;-webkit-transition-property:border-color,-webkit-box-shadow;transition-property:border-color,-webkit-box-shadow;transition-property:box-shadow,border-color;transition-property:box-shadow,border-color,-webkit-box-shadow;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715)
}
@media (min-width:768px){
.my-text-input>.input-wrapper{display:inline-block;width:302px
}
}
.my-text-input>.input-wrapper[focus-within]{border-color:#ff9b46;-webkit-box-shadow:0 0 10px #ff9b46;box-shadow:0 0 10px #ff9b46
}
.my-text-input>.input-wrapper:focus-within{border-color:#ff9b46;-webkit-box-shadow:0 0 10px #ff9b46;box-shadow:0 0 10px #ff9b46
}
.my-text-input>.input-wrapper[focus-within]>input{outline:0
}
.my-text-input>.input-wrapper:focus-within>input{outline:0
}
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{border-bottom:none;border-left:none;border-right:0 solid transparent;border-top:none;display:block;-webkit-transition-duration:.3s;transition-duration:.3s;-webkit-transition-property:border-right-color,border-right-width;transition-property:border-right-color,border-right-width;-webkit-transition-timing-function:cubic-bezier(.47,0,.745,.715);transition-timing-function:cubic-bezier(.47,0,.745,.715);border-radius:3px;width:100%
}
@media (max-width:424px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-left:12px;padding-right:12px
}
}
@media (min-width:425px) and (max-width:767px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-left:13px;padding-right:13px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-left:13px;padding-right:13px
}
}
@media (min-width:1024px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-left:14px;padding-right:14px
}
}
@media (max-width:424px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-top:6px;padding-bottom:6px
}
}
@media (min-width:425px) and (max-width:767px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-top:7px;padding-bottom:7px
}
}
@media (min-width:768px) and (max-width:1023px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-top:7px;padding-bottom:7px
}
}
@media (min-width:1024px){
.my-text-input>.input-wrapper>input:not([type]),.my-text-input>.input-wrapper>input[type=email],.my-text-input>.input-wrapper>input[type=text]{padding-top:8px;padding-bottom:8px
}
}
form.submitted .input-wrapper>input:not([type]).invalid,form.submitted .input-wrapper>input[type=email].invalid,form.submitted .input-wrapper>input[type=text].invalid{border-right-color:#de5858
}
@media (max-width:424px){
form.submitted .input-wrapper>input:not([type]).invalid,form.submitted .input-wrapper>input[type=email].invalid,form.submitted .input-wrapper>input[type=text].invalid{border-right-width:3px
}
}
@media (min-width:425px) and (max-width:767px){
form.submitted .input-wrapper>input:not([type]).invalid,form.submitted .input-wrapper>input[type=email].invalid,form.submitted .input-wrapper>input[type=text].invalid{border-right-width:4px
}
}
@media (min-width:768px) and (max-width:1023px){
form.submitted .input-wrapper>input:not([type]).invalid,form.submitted .input-wrapper>input[type=email].invalid,form.submitted .input-wrapper>input[type=text].invalid{border-right-width:5px
}
}
@media (min-width:1024px){
form.submitted .input-wrapper>input:not([type]).invalid,form.submitted .input-wrapper>input[type=email].invalid,form.submitted .input-wrapper>input[type=text].invalid{border-right-width:5px
}
}